<template>
<v-container fluid>

    <v-container v-if="loading">
      <v-overlay 
        :value="overlay"
        :z-index="zIndex">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="8">
        <h1 class="font-weight-light">Add New Drawing №<span></span>&#32;<span class="font-weight-bold">{{select.design_id}}</span>
          <small class="font-weight-thin">*Displaying preview of database drawing №</small>
          <v-spacer></v-spacer>
        </h1>



      
       

    </v-col>
    <v-col md="4">
        <h4 class="font-weight-thin">
        Adding drawing as: <span class="font-weight-medium">{{currentUser.username}}</span>
        </h4>
    </v-col>
    </v-row>


      <v-form enctype="multipart/form-data" v-model="isFormValid" ref="form">
      <v-row class="pt-0 pb-0 mb-n6 mt-0" align="center">

      <v-col md="4">
        <v-select
          label="Design Reference No."
          v-model="select"
          :hint="`${select.design_id}, ${select.designNumber}`"
          :items="items"
          item-text="design_id"
          item-value="design_id"
          persistent-hint
          return-object
          single-line
          :rules="dwgRule"
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col md="3">
        <v-text-field
         label ="Description"
         name = "description"
         v-model = description
         :rules="sufxRule"
         outlined
         dense
      />
      </v-col>

      <v-col md="2">
        <v-text-field
         label ="Year taken"
         name = "datetaken"
         v-model = datetaken
         :rules="YearRules"
         outlined
         dense
      />
      </v-col>

      </v-row>

    <v-row class="pt-0 pb-0 mb-n4 mt-0">
      <v-col cols="md-5">
        <v-file-input
            prepend-icon="mdi-cloud-upload-outline"
            @change="onFileSelected"
            v-model="files"
            show-size
            counter
            dense
            outlined
        ></v-file-input>
      </v-col>
    </v-row>
            <v-row md="12" class="mr-1 ml-1" align="center">
            <v-textarea
              label ="Notes"
              name = "note"
              v-model = note
              outlined
            ></v-textarea>
            </v-row>

          </v-form>

      <v-container>
        <v-col md="12" class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            v-if="notSaved"
            color="blue lighten-1"
            text
            outlined
            :disabled="!isFormValid"
            @click="create"
          >
            INPUT PHOTO
          </v-btn>
        </v-col>

          </v-container>
          

    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="green darken-1"
          text
          v-bind="attrs"
          @click="navigateTo()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

            <v-alert
              border="right"
              colored-border
              type="error"
              v-if="error">{{error}}>
            </v-alert>
          </v-container>
  
</template>

<script>
import PhotoService from '@/services/PhotoService'
import DesignsService from '@/services/DesignsService'
import DrawingDropDownService from '@/services/DrawingDropDownService'
const FormData = require('form-data')

export default {
  data () {
    return {
      files: [],
      snackbar: false,
      text: 'Photo Saved Successfully.',
      isFormValid: false,
      notSaved: true,
      loading: false,
      overlay: false,
      zIndex: 0,
      error: '',
      valid: false,
      sufxRule: [ v => !!v || 'description required'],
      dwgRule: [ v => !!v || 'Design reference is required'],
      YearRules: [
        (value) => (value && value.length === 4) || 'Must be whole year',
      ],
      selectedFile: null,
      createdBy: this.$store.state.auth.user,
      design_id: '',
      note: '',
      description: '',
      datetaken: '',
      designerId: null,
      drawingdropdown: [{}],
      select: { design_id: '', designNumber: '' },
      items: [{
        design_id: '',
        designNumber: ''
      }],
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    onFileSelected: function(files) {
      console.log(files)
      return this.files
},
    async create () {
      this.notSaved = false
      this.loading = true
      this.overlay = true
      const fd = new FormData()
      fd.append('designNumber', this.select.designNumber)
      fd.append('design_id', this.select.design_id)
      fd.append('description', this.description)
      fd.append('note', this.note)
      fd.append('datetaken', this.datetaken)
      fd.append('file', this.files)
      fd.append('createdBy', this.$store.state.auth.user.id)
      try {
        await PhotoService.post(fd)
      this.loading = false
      this.overlay = false
      this.snackbar=true
      //this.postSuccess=true
      } catch (err) {
        console.log(err)
      }
    },
    navigateTo () {
      this.snackbar=false,
      this.$router.push({
        name: 'Designs'
      })
    },
    newDrawing () {
      this.$router.go(this.$router.currentRoute)
    }
  },
  async mounted () {
    try {
      this.items = (await DesignsService.index()).data
      this.drawingdropdown = (await DrawingDropDownService.index()).data
    } catch (err) {
      console.log(err)
    }
  }
}
</script>

<style scoped>

</style>
