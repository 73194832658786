<template>
<v-container fluid class="grey lighten-5">

    <v-row class="mb-1" align="start">
      <v-col md="4">
        <h1 class="font-weight-light">{{builder.name}}</h1>
        <h3 class="font-weight-thin">{{builder.founder}}</h3>
      </v-col>
      <v-col md="3">
        <h4 class="font-weight-thin">Est: {{builder.dateFrom}}</h4>
      </v-col>
      <v-col md="3">
        <h4 class="font-weight-thin">To: {{builder.dateTo}}</h4>
      </v-col>
    </v-row>
      <v-row>
        <v-col md="12">
          <p class="font-weight-light">{{builder.builderDescription}}</p>
        </v-col>
      </v-row>
    <v-row align="center" class="pt-0 pb-0 mb-0 mt-0">
      <v-col md="12">
       <p class="font-weight-light"></p>
       <a :href="builder.link" target="_blank">{{builder.link}}</a>
      </v-col>
    </v-row>

    <div  v-if="this.designs">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="designs"
            item-key="design_id"
            class="elevation-0 row-pointer"
            :items-per-page="100"
            @click:row="goToDesign"
            hide-default-footer
            dense
          ></v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BuilderService from '@/services/BuilderService'

export default {
  data: () => ({
    builder: [],
    designs: [],
    title: '',
    headers: [
      { text: 'Design №', align: 'center', sortable: true, value: 'design_id' },
      { text: 'Year', value: 'year', sortable: true },
      { text: 'Client', value: 'client', sortable: false },
      { text: 'Name / Class', align: 'center', value: 'nameClass', sortable: false },
      { text: 'Job Description', value: 'jobDescription', sortable: false },
      { text: 'L.O.A (Ft)', value: 'loa', sortable: false },
      { text: 'D.W.L (Ft)', value: 'dwl', sortable: false },
      { text: 'Beam (Ft)', value: 'beam', sortable: false },
      { text: 'Design Type', value: 'designType', sortable: false }
    ]
  }),
  async mounted () {
    const BuildId = this.$route.params.BuildId
    console.log(BuildId)
    this.designs = (await BuilderService.show(BuildId)).data
    this.builder = (await BuilderService.find(BuildId)).data
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    },
    goToDesign (item) {
      this.$router.push(`/designs/${item.design_id}`)
    }
  }
}
</script>

<style scoped>
.font-weight-light {
  white-space: break-spaces;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
