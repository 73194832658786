<template>
  <v-container fluid class="grey lighten-5">
    <v-row class="mb-6" align="center">
      <v-col md="4">
        <h1 class="font-weight-light">All Sail Plans</h1>
         <small>Sail Plans</small>
      </v-col>
       </v-row>

    <v-container fluid v-for="drawing in drawings" :key="drawing.dwgNumber" class="mb-n4">
      <v-card outlined tile>
         <v-list-item three-line>
          <v-img
          class="justify-start pl-0 ml-0 pointer-link"
          aspect-ratio="1.7"
          contain
          :src="drawing.fileUrl"
          @click="navigateTo({
              name: 'DrawingView',
              params: {
                DwgId: drawing.dwgNumber
              }
            })"></v-img>
          <v-list-item-content class="mb-6 pt-4 pb-4 pl-8">
              <v-list-item-title class="font-weight-thin headline mb-1">
              Drawing №: <span class="font-weight-bold">{{drawing.dwgNumber}}</span></v-list-item-title>
            <v-list-item-subtitle class="headline mb-1">
              {{drawing.design.nameClass}}</v-list-item-subtitle>
            <v-list-item-subtitle>Design №:  <span class="font-weight-bold">{{drawing.design_id}}</span></v-list-item-subtitle>

            <v-list-item-subtitle v-if="drawing.architects.length">
              Drawing initials:
                <div class="pl-3" v-for="item in drawing.architects" :key="item.designer_Id" style="display:inline">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="navigateTo({
                                name: 'DesignerDrawing',
                                params: {
                                  archId: item.designer_id
                                }
                              })">
                          {{ item.drawingInitials }}
                          </v-chip>
                        </template>
                        <span>All drawings by {{item.name}}</span>
                      </v-tooltip>
                    </div>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              <v-divider></v-divider>
              <v-row>
                <v-col>
                  <div class="overline mb-4">
                {{drawing.fileSize}}:Kb
                  </div>
                </v-col>
              <v-col>
                <div class="overline mb-4">
                  {{drawing.fileType}}
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
          </v-list-item-content>
          </v-list-item>

        </v-card>
      </v-container>


  </v-container>
</template>

<script>
import DrawingService from '@/services/DrawingService'

export default {
  data () {
    return {
      drawings: {}
    }
  },
  async mounted () {
    this.drawings = (await DrawingService.allSail()).data
  },
  methods: {
    DrawingsCreate () {
      this.$router.push({
        name: 'createdrawing'
      })
    },
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.pointer-link {
    cursor: pointer;
}
.v-image__image--contain {
  background-position: left !important;
}
</style>
