<template>
 <v-list nav dense>

       <div v-for="item in items" :key="item.title">

        <v-list-item
            v-if="!item.subLinks"
            router :to="item.name"
            class="v-list-item"
        >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="item.title" />
        </v-list-item>

        <v-list-group
            v-else
            :key="item.title"
            no-action
            :prepend-icon="item.icon"
            :value="false"
        >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
             </template>

            <v-list-item
                v-for="sublink in item.subLinks"
                router :to="sublink.name"
                :key="sublink.title"
            >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ sublink.title }}</v-list-item-title>

            </v-list-item>

        </v-list-group>

    </div>
    <v-divider role="presentation"></v-divider>

<div v-if="isCurator">

        <v-list-item>
        <v-list-item-content>
          <br>
          <v-list-item-title class="text-h6">
            Archive
          </v-list-item-title>
          <v-list-item-subtitle>
            Curator
          </v-list-item-subtitle>
          <br>
        </v-list-item-content>
      </v-list-item>

       <div v-for="curator in curators" :key="curator.title">

        <v-list-item
            v-if="!curator.subLinks"
            router :to="curator.name"
            class="v-list-item"
        >
            <v-list-item-icon>
                <v-icon>{{ curator.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="curator.title" />
        </v-list-item>

        <v-list-group
            v-else
            :key="curator.title"
            no-action
            :prepend-icon="curator.icon"
            :value="false"
        >
            <template v-slot:activator>
              <v-list-item-title>{{ curator.title }}</v-list-item-title>
             </template>

            <v-list-item
                v-for="sublink in curator.subLinks"
                router :to="sublink.name"
                :key="sublink.title"
            >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ sublink.title }}</v-list-item-title>

            </v-list-item>

        </v-list-group>
       </div>
      </div>

<div v-if="isAdmin">

        <v-list-item>
        <v-list-item-content>
          <br>
          <v-list-item-title class="text-h6">
            Archive
          </v-list-item-title>
          <v-list-item-subtitle>
            Admin
          </v-list-item-subtitle>
          <br>
        </v-list-item-content>
      </v-list-item>

       <div v-for="admin in admins" :key="admin.title">

        <v-list-item
            v-if="!admin.subLinks"
            router :to="admin.name"
            class="v-list-item"
        >
            <v-list-item-icon>
                <v-icon>{{ admin.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="admin.title" />
        </v-list-item>

        <v-list-group
            v-else
            :key="admin.title"
            no-action
            :prepend-icon="admin.icon"
            :value="false"
        >
            <template v-slot:activator>
              <v-list-item-title>{{ admin.title }}</v-list-item-title>
             </template>

            <v-list-item
                v-for="sublink in admin.subLinks"
                router :to="sublink.name"
                :key="sublink.title"
            >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ sublink.title }}</v-list-item-title>

            </v-list-item>

        </v-list-group>
       </div>
      </div>


    </v-list>




</template>

<script>
export default {
  data () {
    return {
      curators: [
        {
          title: 'Designs Curate',
          icon: 'mdi-folder-open-outline',
                    subLinks: [
            {
              name: '/curator/designs/add',
              title: 'Add Design Entry',
              icon: 'mdi-plus-circle-outline'
            },
            {
              name: '/curator/drawings/add',
              title: 'Add Drawing',
              icon: 'mdi-pencil-plus-outline'
            },
            {
              name: '/curator/photos/add',
              title: 'Add Photo',
              icon: 'mdi-image'
            },
          ]
        },
      ],
      admins: [
        {
          title: 'Members',
          icon: 'mdi-account-multiple',
                    subLinks: [
            {
              name: '/admin/register',
              title: 'Add New Member',
              icon: 'mdi-account-plus-outline'
            },
          ]
        },
      ],

      items: [
        {
          name: `/profile/${this.$store.state.auth.user.id}`,
          title: `${this.$store.state.auth.user.username}`,
          icon: 'mdi-account-circle'
        },
        {
          name: '/designs',
          title: 'Designs',
          icon: 'mdi-folder-open-outline'
        },
        {
          title: 'Drawings',
          icon: 'mdi-android-studio',
          subLinks: [
            {
              name: '/drawings',
              title: 'Drawing Index',
              icon: 'mdi-folder-table-outline'
            },
            {
              name: '/lines',
              title: 'LinesPlans',
              icon: 'mdi-draw'
            },
            {
              name: '/sailplans',
              title: 'Sail Plans',
              icon: 'mdi-sail-boat'
            },
            {
              name: '/typeplans',
              title: 'Type plans',
              icon: 'mdi-ruler-square'
            }
          ]
        },
        {
          name: '/yachts',
          title: 'Yachts',
          icon: 'mdi-folder-outline'
        },
        {
          name: '/builders',
          title: 'Builders',
          icon: 'mdi-hammer-wrench'
        },
        {
          // name: '/articles',
          title: 'Articles',
          icon: 'mdi-book-open-variant',
          subLinks: [
            {
              name: '/articles',
              title: 'Article Index',
              icon: 'mdi-folder-table-outline'
            },
            {
              name: '/authors',
              title: 'Authors',
              icon: 'mdi-account-edit-outline'
            },
            {
              name: '/technical',
              title: 'Technical Papers',
              icon: 'mdi-android-studio'
            },
            {
              name: '/brochures',
              title: 'Brochures',
              icon: 'mdi-book-open-page-variant-outline'
            }
          ]
        },
        {
          name: '/members',
          title: 'Members',
          icon: 'mdi-account-multiple'
        },
        {
          name: '/photos',
          title: 'Photos',
          icon: 'mdi-image'
        },
        {
          name: '/about',
          title: 'About',
          icon: 'mdi-help-box'
        }
      ]
    }
  },
  methods: {
    NavigateTo (route) {
      this.$router.push(route)
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    isCurator() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
}


</script>

<style scoped>
.home {
  cursor: pointer;
}
</style>
