<template>
<v-container fluid>
  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="3">
        <h1 class="font-weight-light">Drawings</h1>
    </v-col>
    <v-col md="3">
      
    </v-col>

    <v-col md="3" justify="end">
      <v-text-field
        outlined
        dense
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-col>
    </v-row>

    <v-container v-if="loading" class="d-flex justify-space-around mb-6">
      <v-overlay 
        :value="overlay"
        :z-index="zIndex">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

    <br>
 <v-data-table
    dense
    :headers="headers"
    :items="drawings"
    item-key="dwgNumber"
    :items-per-page="15"
    sort-by="designNumber"
    :sort-asc="true"
    :search="search"
    @click:row="goToDrawing"
    class="row-pointer">

        <template v-slot:[`item.architects`]="{ item }">
          <div class="pr-3" v-for="architects in item.architects" :key="architects.designer_Id" style="display:inline">
            <td>
              <v-chip x-small>
              {{ architects.drawingInitials }}
              </v-chip>
              </td>
          </div>
        </template>

  </v-data-table>
    <v-alert
      border="right"
      colored-border
      type="error"
      v-if="error">{{error}}>
    </v-alert>

</v-container>
</template>


<script>
import DrawingService from '@/services/DrawingService'
export default {
  data: () => ({
    loading: true,
    overlay: true,
    zIndex: 0,
    search: '',
    error: '',
    designs: [],
    title: '',
    expanded: [],
    drawings: [{
      architects: [{
        drawingInitials: '',
      }],
    }],
    headers: [
      { text: 'Drawing №', sortable: true, value: 'dwgNumber' },
      { text: 'Description', value: 'drawingtype.type', sortable: true },
      { text: 'Design №', sortable: true, value: 'design_id' },
      { text: 'Date', value: 'date', sortable: true },
      { text: 'Alteration', align: 'center', value: 'alt', sortable: true },
      { text: 'Designer Initials', align: 'center', value: 'architects', sortable: true },
      { text: 'Size (Kb)', value: 'fileSize', sortable: true }
    ]
  }),
  async mounted () {
    try {
    this.drawings = (await DrawingService.index()).data
      this.loading = false
      this.overlay = false
    } catch(err) {
      this.error = err.message
    }
  },
  methods: {
    DrawingsCreate () {
      this.$router.push({
        name: 'createdrawing'
      })
    },
    goToDrawing (item) {
      this.$router.push(`/drawings/${item.dwgNumber}`)
    }
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
