<template>
  <v-footer padless>
    <v-col
      class="text-center"
      cols="12">
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          icon
        >
          <v-icon small>
            {{ icon }}
          </v-icon>
        </v-btn>
        {{ new Date().getFullYear() }} — S&S Association
    </v-col>
    <!--<v-row>
      <v-col md="2">
      <v-btn icon class="mx-4">
        <v-icon small @click="goToFacebook">mdi-facebook</v-icon>
      </v-btn>
    </v-col>
      <v-col
        md="2">

      </v-col>
      <v-col
        md="2">

      </v-col>
    </v-row>-->
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-instagram'
    ]
  }),
  methods: {
    //gotoGoogle() {
    //  window.open("https://www.instagram.com/sparkmanstephens_association/");
    //},
    //goToFacebook() {
    //  window.open("https://www.instagram.com/sparkmanstephens_association/");
    //},
  }
}
</script>

<style scoped>

</style>
