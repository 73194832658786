var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-between mb-6",attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"8"}},[_c('h1',{staticClass:"font-weight-light"},[_vm._v("Drawing № "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.drawing.dwgNumber))]),_c('span',{staticClass:"font-weight-thin"},[_vm._v(" "+_vm._s(_vm.drawing.drawingtype.type))])]),_c('br')]),(_vm.loading)?_c('v-container',{staticClass:"d-flex justify-space-around mb-6"},[_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":_vm.zIndex}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1):_vm._e(),_c('v-col',{staticClass:"pl-0 pt-8",attrs:{"align":"end","md":"4"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.navigateTo({
            name: 'viewDesign',
            params: {
              DesId: _vm.drawing.design_id
            }
          })}}},'v-btn',attrs,false),on),[_c('h3',{staticClass:"font-weight-thin"},[_vm._v("Design № "+_vm._s(_vm.drawing.design_id))])])]}}])},[(_vm.drawing.design)?_c('span',[_vm._v("Explore "+_vm._s(_vm.drawing.design.nameClass)+" for "+_vm._s(_vm.drawing.design.client))]):_vm._e()])],1)],1),_c('v-row',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"align":"start"}},[_c('v-col',{staticClass:"d-flex pa-2",attrs:{"md":"12"}},[_c('v-img',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"contain":"","src":_vm.imageLink}})],1)],1),_c('v-row',[_c('v-col',[_c('v-simple-table',[[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Drawing Number ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Year ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Alteration ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Initials ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Notes ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.drawing.dwgNumber))]),_c('td',[_vm._v(_vm._s(_vm.drawing.date))]),_c('td',[_vm._v(_vm._s(_vm.drawing.alt))]),_c('td',_vm._l((_vm.drawing.architects),function(item){return _c('div',{key:item.designer_id,staticClass:"pr-3",staticStyle:{"display":"inline"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.navigateTo({
                              name: 'DesignerDrawing',
                              params: {
                                archId: item.designer_id
                              }
                            })}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.drawingInitials)+" ")])]}}],null,true)},[_c('span',[_vm._v("All drawings by "+_vm._s(item.name))])])],1)}),0),_c('td',[_vm._v(_vm._s(_vm.drawing.note))])])])]],2)],1)],1),(_vm.error)?_c('v-alert',{attrs:{"border":"right","colored-border":"","type":"error"}},[_vm._v(_vm._s(_vm.error)+"> ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }