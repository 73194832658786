import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// Login and Register 
import Login from '../components/Users/Login.vue'
import Profile from '../components/Users/Profile.vue'

// Architects
import DesignerDrawing from '../components/Architects/DesignerDrawing.vue'

// Designs
import Designs from '../components/Designs/Designs.vue'
import DesignsMetric from '../components/Designs/DesignsMetric.vue'
import ViewDesign from '../components/Designs/ViewDesign.vue'

// Drawings
import Drawings from '../components/Drawings/Drawings.vue'
import DrawingView from '../components/Drawings/DrawingView.vue'
import LinesPlans from '../components/Drawings/LinesPlans.vue'
import SailPlans from '../components/Drawings/SailPlans.vue'

// Photos
import PhotosAll from '../components/Photos/PhotosAll.vue'

// Builders
import Builders from '../components/Builders/Builders.vue'
import ViewBuilder from '../components/Builders/ViewBuilder.vue'

// Curator Routes
import DesignsCurate from '../components/Curator/DesignsCurate.vue'
import DesignCreate from '../components/Curator/DesignCreate.vue'
import DrawingArchitectCreate from '../components/Curator/DrawingArchitectCreate.vue'
import DrawingCreate from '../components/Curator/DrawingCreate.vue'
import PhotoCreate from '../components/Curator/PhotoCreate.vue'

// Admin Routes
import Register from '../components/Users/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // Login & Register 
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/profile/:UserId',
    name: 'Profile',
    component: Profile
  },

  // Designs Master List
  {
    path: '/designs',
    name: 'Designs',
    component: Designs,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/designs-metric',
    name: 'DesignsMetric',
    component: DesignsMetric
  },
  {
    path: '/designs/:DesId',
    name: 'viewDesign',
    component: ViewDesign
  },

  // ARCHITECT ROUTES
  {
    path: '/architects/drawings/:archId',
    name: 'DesignerDrawing',
    component: DesignerDrawing
  },

  // Drawings Routes
  {
    path: '/drawings',
    name: 'Drawings',
    component: Drawings
  },
  {
    path: '/drawings/:DwgId',
    name: 'DrawingView',
    component: DrawingView
  },
  {
    path: '/lines',
    name: 'LinesPlans',
    component: LinesPlans
  },
  {
    path: '/sailplans',
    name: 'SailPlans',
    component: SailPlans
  },
  // BUILDER ROUTES
  {
    path: '/builders',
    name: 'Builders',
    component: Builders
  },
  {
    path: '/builder/:BuildId',
    name: 'ViewBuilder',
    component: ViewBuilder
  },
  // PHOTO ROUTES
  {
    path: '/photos',
    name: 'PhotosAll',
    component: PhotosAll
  },
  // CURATOR ROUTES
  {
    path: '/curator/designs',
    name: 'CurateDesigns',
    component: DesignsCurate
  },
  {
    path: '/curator/designs/add',
    name: 'CreateDesigns',
    component: DesignCreate
  },
  {
    path: '/curator/drawings/add',
    name: 'CreateDrawing',
    component: DrawingCreate
  },
  {
    path: '/curator/latest',
    name: 'DrawingArchitectCreate',
    component: DrawingArchitectCreate
  },
  {
    path: '/curator/photos/add',
    name: 'PhotoCreate',
    component: PhotoCreate
  },
// ADMIN ROUTES
{
  path: '/admin/register',
  name: 'Register',
  component: Register
},
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
