import Api from '@/services/Api'

export default {
  index () {
    return Api().get('architects')
  },
  show (archId) {
      return Api().get(`architect/${archId}`)
  },
  initialsDropDown () {
    return Api().get('architects/initials')
  },
  architectsDrawings (archId) {
    return Api().get(`architects/drawings/${archId}`)
  },
}

