var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-6",attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('h1',{staticClass:"font-weight-light"},[_vm._v("All Sail Plans")]),_c('small',[_vm._v("Sail Plans")])])],1),_vm._l((_vm.drawings),function(drawing){return _c('v-container',{key:drawing.dwgNumber,staticClass:"mb-n4",attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-img',{staticClass:"justify-start pl-0 ml-0 pointer-link",attrs:{"aspect-ratio":"1.7","contain":"","src":drawing.fileUrl},on:{"click":function($event){return _vm.navigateTo({
            name: 'DrawingView',
            params: {
              DwgId: drawing.dwgNumber
            }
          })}}}),_c('v-list-item-content',{staticClass:"mb-6 pt-4 pb-4 pl-8"},[_c('v-list-item-title',{staticClass:"font-weight-thin headline mb-1"},[_vm._v(" Drawing №: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(drawing.dwgNumber))])]),_c('v-list-item-subtitle',{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(drawing.design.nameClass))]),_c('v-list-item-subtitle',[_vm._v("Design №: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(drawing.design_id))])]),(drawing.architects.length)?_c('v-list-item-subtitle',[_vm._v(" Drawing initials: "),_vm._l((drawing.architects),function(item){return _c('div',{key:item.designer_Id,staticClass:"pl-3",staticStyle:{"display":"inline"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.navigateTo({
                              name: 'DesignerDrawing',
                              params: {
                                archId: item.designer_id
                              }
                            })}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.drawingInitials)+" ")])]}}],null,true)},[_c('span',[_vm._v("All drawings by "+_vm._s(item.name))])])],1)})],2):_vm._e(),_c('v-list-item-subtitle',[_c('v-divider'),_c('v-row',[_c('v-col',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(drawing.fileSize)+":Kb ")])]),_c('v-col',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(drawing.fileType)+" ")])])],1)],1)],1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }