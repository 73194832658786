import Api from '@/services/Api'
//import authHeader from '@/services/Auth-header'

export default {
  index () {
    return Api().get('builders')
  },
  dropDown () {
    return Api().get('builders/dropdown')
  },
  show (BuildId) {
    return Api().get(`builders/${BuildId}`)
  },
  post (builder) {
    return Api().post('builder/create', builder)
  },
  find (BuildId) {
    return Api().get(`builder/${BuildId}`)
  },
  put (builder) {
    return Api().put(`builder/${builder.builderId}`, builder)
  },
  //edit (typeplan) {
  //  return Api().put(`typeplans/${typeplan.planNumber}`, typeplan, { headers: authHeader() })
  //},
  // delete (typeplan) {
  //   return Api().delete(`deletetypeplans/${typeplan.planNumber}`, typeplan)
  // }
}
