<template>
<v-container fluid>

    <v-container v-if="loading">
      <v-overlay 
        :value="overlay"
        :z-index="zIndex">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="8">
        <h1 class="font-weight-light">Add New Drawing №<span></span>&#32;<span class="font-weight-bold">{{select.design_id}}</span>
          <span v-if="dwgSuffix">-{{dwgSuffix}}</span></h1>
          <small class="font-weight-thin">*Displaying preview of database drawing №</small>
          <v-spacer></v-spacer>

    </v-col>
    <v-col md="4">
        <h4 class="font-weight-thin">
        Adding drawing as: <span class="font-weight-medium">{{currentUser.username}}</span>
        </h4>
    </v-col>
    </v-row>


      <v-form enctype="multipart/form-data" v-model="isFormValid" ref="form">
      <v-row class="pt-0 pb-0 mb-n6 mt-0" align="center">

      <v-col md="4">
        <v-select
          label="Design Reference No."
          v-model="select"
          :hint="`${select.design_id}, ${select.designNumber}`"
          :items="items"
          item-text="design_id"
          item-value="design_id"
          persistent-hint
          return-object
          single-line
          :rules="dwgRule"
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col md="3">
        <v-text-field
         label ="Drawing Suffix"
         name = "dwgSuffix"
         v-model = dwgSuffix
         :rules="sufxRule"
         outlined
         dense
      />
      </v-col>

      <v-col md="2">
        <v-text-field
         label ="Alteration"
         name = "alt"
         v-model = alt
         outlined
         dense
      />
      </v-col>

      </v-row>
      <v-row class="pt-0 pb-0 mb-n4 mt-0">

        <v-col md="6">
          <v-select
            label = "Drawing Type"
            v-model="dwgType_id"
            :items="drawingdropdown" item-text="type" item-value="dwgType"
            outlined
            dense
            ><option v-for="drawingtype in drawingdropdown.drawingtype"  v-bind:key="drawingtype">{{ type }}</option>
          </v-select>
        </v-col>

<!-- TEMP TEST ON MULTIPLE SELECT -->
<v-col md="6">

    <v-select
      v-model="selectedInitials"
      :items="designers"
          item-text="drawingInitials"
          item-value="designer_id"
      label="Drawing Signatures"
      dense
      outlined
      multiple
      chips
    >
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
        >
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
</v-col>

<!-- END TEMP TEST ON MULTIPLE SELECT -->

      <!--<v-col md="6">
        <v-select
          label = "Designer Initials"
          v-model="architects"
          :items="designers" item-text="drawingInitials" item-value="id"
          outlined
          dense
          multiple
          chips
          persistent-hint
          >
        </v-select>
      </v-col>-->

      </v-row>

    <v-row class="pt-0 pb-0 mb-n4 mt-0">
      <v-col cols="md-5">
        <v-file-input
            prepend-icon="mdi-cloud-upload-outline"
            @change="onFileSelected"
            v-model="files"
            show-size
            counter
            dense
            outlined
        ></v-file-input>
      </v-col>

          <v-col md="7">
            <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Drawing date"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :rules="dateRule"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

            </v-row>
            <v-row md="12" class="mr-1 ml-1" align="center">
            <v-textarea
              label ="Notes"
              name = "note"
              v-model = note
              outlined
            ></v-textarea>
            </v-row>

          </v-form>

      <v-container>
        <v-col md="12" class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            v-if="notSaved"
            color="blue lighten-1"
            text
            outlined
            :disabled="!isFormValid"
            @click="create"
          >
            INPUT DRAWING
          </v-btn>
        </v-col>

          </v-container>
          

    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="green darken-1"
          text
          v-bind="attrs"
          @click="navigateTo()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

            <v-alert
              border="right"
              colored-border
              type="error"
              v-if="error">{{error}}>
            </v-alert>
          </v-container>
  
</template>

<script>
import DrawingService from '@/services/DrawingService'
import DesignsService from '@/services/DesignsService'
import ArchitectService from '@/services/ArchitectService'
import DrawingDropDownService from '@/services/DrawingDropDownService'
const FormData = require('form-data')

export default {
  data () {
    return {
      files: [],
      snackbar: false,
      text: 'Drawing Entered Successfully.',
      isFormValid: false,
      notSaved: true,
      loading: false,
      overlay: false,
      zIndex: 0,
      error: '',
      valid: false,
      date: null,
      menu: false,
      modal: false,
      menu2: false,
      sufxRule: [ v => !!v || 'Drawing suffix required to create Dwg Number'],
      dateRule: [ v => !!v || 'Date required, use approximation of design date if not known'],
      dwgRule: [ v => !!v || 'Design reference is required'],
      designerRules: [ v => !!v || 'Designer Initials Required / Select N/A if not known'],
      selectedFile: null,
      createdBy: this.$store.state.auth.user,
      dwgSuffix: '',
      dwgType_id: '',
      design_id: '',
      note: '',
      alt: '',
      architects: '',
      designers: [{ id: '', drawingInitials: '' }],
      drawingdropdown: [{}],
      select: { design_id: '', designNumber: '' },
      items: [{
        design_id: '',
        designNumber: ''
      }],
      selectedInitials: [],
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    onFileSelected: function(files) {
      console.log(files)
      return this.files
},
    async create () {
      this.notSaved = false
      this.loading = true
      this.overlay = true
      //this.newselectedInitials = this.selectedInitials.filter(value => JSON.stringify(value) !== '{}');
      const fd = new FormData()
      fd.append('designNumber', this.select.designNumber)
      fd.append('dwgSuffix', this.dwgSuffix)
      fd.append('design_id', this.select.design_id)
      fd.append('dwgType_id', this.dwgType_id)
      fd.append('note', this.note)
      fd.append('alt', this.alt)
      fd.append('date', this.date)
      fd.append('file', this.files)
      fd.append('createdBy', this.$store.state.auth.user.id)
      fd.append( 'selectedInitials', this.selectedInitials )
      try {
        console.log(this.selectedInitials)
        console.log(this.fd)
        await DrawingService.post(fd)
      this.loading = false
      this.overlay = false
      this.snackbar = true
      //this.postSuccess=true
      } catch (err) {
        console.log(err)
      }
    },
    navigateTo () {
      this.snackbar=false,
      this.$router.push({
        name: 'Drawings'
      })
    },
    newDrawing () {
      this.$router.go(this.$router.currentRoute)
    },
    toggle () {
    this.$nextTick(() => {
        console.log(this.selectedInitials)
        return this.selectedInitials = []
    })
    },
  },
  async mounted () {
    try {
      this.items = (await DesignsService.index()).data
      this.drawingdropdown = (await DrawingDropDownService.index()).data
      this.designers = (await ArchitectService.initialsDropDown()).data
    } catch (err) {
      console.log(err)
    }
  }
}
</script>

<style>
.v-input--dense.v-text-field--outlined .v-chip {
  height: 24px;
  margin-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
}
</style>
