import Api from '@/services/Api'


class AuthService {
    login(user) {
      return Api().post('/auth/signin', {
        username: user.username,
        password: user.password
      })
        .then(response => {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data));
          }
  
          return response.data;
        });
    }
  
    logout() {
      localStorage.removeItem('user');
    }
  
    register(user) {
      return Api().post('/auth/signup', {
        username: user.username,
        email: user.email,
        password: user.password
      });
    }
  }

  export default new AuthService();

  