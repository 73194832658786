<template>
<v-container fluid>
  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="3">
        <h1 class="font-weight-light">Builders</h1>
    </v-col>
    <v-col md="3">
      
    </v-col>

          <v-col md="3" justify="end">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-row>

    <v-data-table
      :headers="headers"
      :items="builders"
      item-key="name"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="25"
      :search="search"
      dense
      @click:row="goToBuilder"
      class="row-pointer">
    ></v-data-table>
  </v-container>
</template>

<script>
import BuilderService from '@/services/BuilderService'
export default {
  data: () => ({
    search: '',
    sortBy: 'name',
    sortDesc: false,
    builders: [],
    title: '',
    headers: [
      { text: 'Name', align: 'left', sortable: true, value: 'name' },
      { text: 'Founder / Proprietor', value: 'founder', sortable: true },
      { text: 'City', align: 'center', sortable: true, value: 'city' },
      { text: 'County / State', align: 'center', sortable: true, value: 'county_state' },
      { text: 'Country', value: 'country', sortable: true }
    ]
  }),
  async mounted () {
    this.builders = (await BuilderService.index()).data
  },
  methods: {
    goToBuilder (item) {
      this.$router.push(`/builder/${item.builder_id}`)
    },
    CreateBuilder () {
      this.$router.push({
        name: 'createBuilder'
      })
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
