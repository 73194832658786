import Api from '@/services/Api'
import authHeader from '@/services/Auth-header'
import store from '@/store/index'
import router from '@/router/index'

export default {
  latestEntry () {
    return Api().get('designs/latest', { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
  latestDrawing () {
    return Api().get('drawings/latest')
  },
  totalDesigns () {
    return Api().get('design/stats')
  },
  totalDrawings () {
    return Api().get('drawing/stats')
  },
  totalPhotos () {
    return Api().get('photo/stats')
  }
}




