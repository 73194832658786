<template>
    <v-main class="pa-0 ma-0">
      <section id="hero">
        <v-row no-gutters>
          <v-img
            :min-height="'calc(100h - ' + $vuetify.application.top + 'px)'"
            :src="require('../assets/Olin+et+all+Office+photo_5x7.jpg')"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col
                    class="white--text text-center"
                    cols="12"
                    tag="h1"
                  >
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light"
                    >
                      WELCOME TO THE
                    </span>

                    <br>

                    <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                      class="font-weight-black"
                    >
                      ARCHIVE
                    </span>

                  </v-col>

                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#about')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
      </v-row>
      <v-col align="end">
        <small class="font-weight-thin">William Starling Burgess, Olin Stephens, Drake Sparkman & Rod Stephens look over the plans for Ranger</small>
      </v-col>
    </section>

      <section id="about">
        <div class="mb-12"></div>

        <v-container class="text-center">
          <v-img
          :src="require('../assets/sandsfortest3.png')"
          contain
          height="90"
        />

          <v-responsive
            class="mt-8 mx-auto title font-weight-light mb-8"
            max-width="720"
          >
          A member-collated archive of the iconic yachts of Sparkman & Stephens. Available for study and research, its primary role is to preserve the materials, knowledge and experience within its care.
          </v-responsive>

           <!--<v-row class="d-flex justify-center my-6">
              <v-col md="2">
                <v-img
                  contain
                  max-height="80"
                  :src="require('../assets/S&S_Assoc Gray Scale.jpg')"
                ></v-img>
              </v-col>
              <v-col md="2">
                <v-img
                  contain
                  max-height="80"
                  :src="require('../assets/S&S Gray No Background.png')"
                ></v-img>
              </v-col>
            </v-row>

          <v-btn
            color="grey"
            href="https://sparkmanstephens.org/"
            outlined
            large
          >
            <span class="grey--text text--darken-1 font-weight-bold">
              wwww.sparkmanstephens.org
            </span>
          </v-btn>-->
        
        <v-row class="my-12" justify="center">
          <v-col md="10">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-container>
    </section>



<section id="latestContent" class="my-12">

  <v-card elevation="0">
    <v-row justify="center">
      <v-col md="10" justify="center">
        <h5>LATEST DESIGN ENTRY:</h5>
    </v-col>
  </v-row>
      <v-row justify="center">
        <v-col md="10" justify="center">
          <v-card>
          <v-simple-table
          class="row-pointer">
            <template>
              <thead>
                <tr>
                  <th class="text-left">
                    Design №
                  </th>
                  <th class="text-left">
                    Year
                  </th>
                  <th class="text-left">
                    Client
                  </th>
                  <th class="text-left">
                    Job Description
                  </th>
                  <th class="text-left">
                    Year
                  </th>
                  <th class="text-left">
                    L.O.A (Ft)
                  </th>
                  <th class="text-left">
                    D.W.L (Ft)
                  </th>
                  <th class="text-left">
                    Beam (Ft)
                  </th>
                  <th class="text-left">
                    Draft (Ft)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ design.design_id }}</td>
                  <td>{{ design.year }}</td>
                  <td>{{ design.client }}</td>
                  <td>{{ design.jobDescription }}</td>
                  <td>{{ design.year }}</td>
                  <td>{{ design.loa }}</td>
                  <td>{{ design.dwl }}</td>
                  <td>{{ design.beam }}</td>
                  <td>{{ design.draft }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    <v-row justify="center">
      <v-col md="2" justify="center">
          <v-btn
          class="grey--text text--darken-1 font-weight-bold"
          color="grey"
          @click="goToDesign"
          outlined
          large>
          explore design #{{design.design_id}}
          </v-btn>
      </v-col>
  </v-row>
  </v-card>

    <v-row justify="center">
      <v-col md="10" justify="center">
        <h5>LATEST DRAWING ENTRY:</h5>
    </v-col>
  </v-row>

  <v-row justify="center">
    <v-col md="10" justify="center">
      <v-container>
        <v-card
        class="mx-auto"
        outlined
        elevation="1">
          <v-list-item three-line>
            <v-img
            class="justify-start pl-0 ml-0 pointer-link"
            aspect-ratio="1.7"
            contain
            :src="drawing.fileUrl"
            @click="navigateTo({
                name: 'DrawingView',
                params: {
                  DwgId: drawing.dwgNumber
                }
              })"></v-img>
            <v-list-item-content class="mb-6 pt-4 pb-4 pl-8">
                <v-list-item-title class="font-weight-thin headline mb-1">
                Drawing №: <span class="font-weight-bold">{{drawing.dwgNumber}}</span></v-list-item-title>
              <v-list-item-subtitle class="headline mb-1" v-if="drawing.design">
                {{drawing.design.nameClass}}</v-list-item-subtitle>
              <v-list-item-subtitle>Design №:  <span class="font-weight-bold">{{drawing.design_id}}</span></v-list-item-subtitle>

              <v-list-item-subtitle v-if="drawing.architects">
                Drawing initials:
                  <div class="pl-3" v-for="item in drawing.architects" :key="item.designer_Id" style="display:inline">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="navigateTo({
                                name: 'DesignerDrawing',
                                params: {
                                  archId: item.designer_id
                                }
                              })">
                        {{ item.drawingInitials }}</v-chip>
                      </template>
                    <span>All drawings by {{item.name}}</span>
                  </v-tooltip>
                </div>
              </v-list-item-subtitle>

              <v-list-item-subtitle>
                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <div class="overline mb-4">
                  {{drawing.fileSize}}:Kb
                    </div>
                  </v-col>
                <v-col>
                  <div class="overline mb-4">
                    {{drawing.fileType}}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
    </v-col>
  </v-row>

</section>


      <section id="stats2">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 800 : 500"
          :src="require('../assets/Olin from Classic Boat Archives2.jpg')"
        >
          <v-container fill-height>
            <v-row class="mx-auto">
              <v-col
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                  >{{this.totalDesigns}}</div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    
                  >DESIGNS</div>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                  >{{this.totalDrawings}}</div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    
                  >DRAWINGS</div>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                  >{{this.totalPhotos}}</div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    
                  >PHOTOGRAPHS</div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                  >0</div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    
                  >TYPEPLANS</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
      </section>


    </v-main>
</template>

<script>
import PublicService from '@/services/PublicService'
export default {

  data: () => ({
    design: {},
    drawing: {},
    totalDesigns: '',
    totalDrawings: '',
    totalPhotos: '',
    totalTypePlans: '',
  }),
  created:
  async function () {
    try {
      this.design = (await PublicService.latestEntry()).data
      this.drawing = (await PublicService.latestDrawing()).data
      this.totalDesigns = (await PublicService.totalDesigns()).data
      this.totalDrawings = (await PublicService.totalDrawings()).data
      this.totalPhotos = (await PublicService.totalPhotos()).data
    } catch (error) {
      this.error = error.message
      console.log(error)
    }
  },
  methods: {
    goToDesign () {
      this.$router.push(`/designs/${this.design.design_id}`)
    },
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.container{
     max-width: 100vw;
     padding:0px;
  }
tr:hover {
        background-color: transparent !important;
     }
.pointer-link {
    cursor: pointer;
}
</style>
