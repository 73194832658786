import Api from '@/services/Api'
import authHeader from '@/services/Auth-header'

export default {
  index () {
    return Api().get('photos', { headers: authHeader() });
  },
  //allLines () {
  //  return Api().get('lines', { headers: authHeader() })
  //},
  //allSail () {
  //  return Api().get('sailplans', { headers: authHeader() })
  //},
  post (fd) {
    return Api().post('curator/photos/add', fd, { headers: authHeader() })
  },
}
