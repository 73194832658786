<template>
<nav>
  <v-app-bar
        dark
        app :clipped-left="true"
        class="pb-10"
        >
        <div class="d-flex align-center">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            @click="showSideBar = !showSideBar"
            >
          <v-img
            alt="sandswhite"
            class="shrink"
            contain
            src="../assets/sandswhite.png"
            transition="scale-transition"
            width="60"
          />
          </v-btn>
              <v-toolbar-title class="font-weight-thin">
                <span
                class="home"
                @click="ToHome()">|   Association</span>
              </v-toolbar-title>
          </div>
        <v-spacer></v-spacer>
        <!--<span v-if="!$store.state.isUserLoggedIn">REGISTER</span>

        <v-btn
        v-if="!$store.state.isUserLoggedIn"
        icon @click="NavigateTo({name:'register'})">
          <v-icon>mdi-anchor</v-icon>
        </v-btn>-->

        <span v-if="!$store.state.auth.status.loggedIn">LOGIN</span>

        <v-btn
        v-if="!$store.state.auth.status.loggedIn"
        icon @click="NavigateTo({name:'Login'})">
        <v-icon dense>mdi-account-circle</v-icon>
        </v-btn>

        <span v-if="$store.state.auth.status.loggedIn">LOGOUT</span>

        <v-btn
        v-if="$store.state.auth.status.loggedIn"
        icon @click="logOut()">
        <v-icon dense>mdi-account-arrow-right</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer
         v-if="$store.state.auth.status.loggedIn"
        app :clipped = "true" height = 100%
        v-model="showSideBar">
      <v-divider></v-divider>
      <NavDraw/>
    </v-navigation-drawer>
    </nav>
</template>

<script>
import NavDraw from '@/components/NavDraw'
export default {
  components: {
    NavDraw
  },
  data () {
    return {
      showSideBar: false
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push({
        name: 'Home'
      });
    },
    NavigateTo (route) {
      this.$router.push(route)
    },
    ToHome () {
      this.$router.push({
        name: 'Home'
      })
    }
  }
}

</script>

<style scoped>
.home {
  cursor: pointer;
}
</style>
