import Api from '@/services/Api'
import authHeader from '@/services/Auth-header'

export default {
  index () {
    return Api().get('drawings', { headers: authHeader() });
  },
  allLines () {
    return Api().get('lines', { headers: authHeader() })
  },
  allSail () {
    return Api().get('sailplans', { headers: authHeader() })
  },
  post (fd) {
    return Api().post('curator/drawings/add', fd, { headers: authHeader() })
  },
  postTest (drawing) {
    return Api().post('raw/add', drawing)
  },
  show (DwgId) {
    return Api().get(`drawings/${DwgId}`, { headers: authHeader() })
  },
  findLatest () {
    return Api().get('latestdwg')
  },
  // Temporary to render file Name
  GetFileName (DwgId) {
    return Api().get(`drawings/${DwgId}`)
  },


  // For Design view - All Drawings by Design
  allByDesign (DwgId) {
    return Api().get(`drawingsbydesign/${DwgId}`)
  },
  put (drawing) {
    return Api().put(`drawings/${drawing.dwgNumber}`, drawing)
  },
  delete (drawing) {
    return Api().delete(`deletedrawing/${drawing.dwgNumber}`, drawing)
  }
}
