import Api from '@/services/Api'

export default {
  index () {
    return Api().get('drawingtypes')
  },
  post (fd) {
    return Api().post('drawingtypescreate', fd)
  },
  show (DwgId) {
    return Api().get(`drawings/${DwgId}`)
  },
  // For Design view - All Drawings by Design
  allByDesign (DesId) {
    return Api().get(`drawingsbydesign/${DesId}`)
  },
  put (drawing) {
    return Api().put(`drawings/${drawing.dwgNumber}`, drawing)
  },
  delete (drawing) {
    return Api().delete(`deletedrawing/${drawing.dwgNumber}`, drawing)
  }
}
