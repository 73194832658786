<template>
<v-container fluid>
  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="8">
        <h1 class="font-weight-light">Add Design Entry №<span></span>&#32;<span class="font-weight-bold">{{design.designNumber}}</span>
          <span v-if="design.copy">-{{design.copy}}</span></h1>
          <small class="font-weight-thin">Displaying preview of database design entry id</small>
          <v-spacer></v-spacer>
          

    </v-col>
    <v-col md="4">
        <h4 class="font-weight-thin">
        Adding entry as: <span class="font-weight-medium">{{currentUser.username}}</span>
        </h4>
    </v-col>
    </v-row>

<v-container fluid class="grey lighten-5">
  <v-form v-model="isFormValid">
        <v-row class="pt-0 pb-0 mb-n6 mt-0" align="center">
        <v-col md="3">
        <v-text-field
         label ="Design Number"
         name = "designNumber"
         v-model = design.designNumber
         :rules="DesignRules"
         outlined
         dense
      />
        </v-col>
        <v-col md="2">
      <v-text-field
         label ="*Copy / Variant"
         name = "copy"
         v-model = design.copy
         outlined
         dense
         hint="*"
      />
        </v-col>
        <v-col md="2">
        <v-text-field
         label ="Year"
         name = "year"
         v-model = design.year
         :rules="YearRules"
         outlined
         dense
      />
        </v-col>
        <v-col md="5">
        <v-text-field
         label ="Name / Class"
         name = "nameClass"
         v-model = design.nameClass
         outlined
         dense
      />
        </v-col>
        </v-row>

          <small class="font-weight-thin">* Copy / Variant - designate 'C1' etc, leave blank if unique</small>
          <v-spacer></v-spacer>

      <v-row class="pt-0 pb-0 mb-n6 mt-0" align="center">
        <v-col md="3">
      <v-text-field
         label ="Client"
         name = "client"
         v-model = design.client
         outlined
         dense
      />
        </v-col>
        <v-col md="4">
      <v-text-field
         label ="Job Description"
         name = "jobDescription"
         outlined
         v-model = design.jobDescription
         dense
        />
        </v-col>
        <v-col md="2">
        <v-select
            :items="['12-meter', 'Bark', 'Catboat', 'Cutter', 'Ketch', 'Motor-Sailor', 'N/A', 'Power', 'Ship', 'Schooner', 'Sloop', 'Yawl']"
            label ="Design Type"
            name = "DesignType"
            required
            dense
            outlined
        ></v-select>
        </v-col>
          <v-col md="3">
            <v-select
            label = "Select Builder*"
            v-model="design.builder_id"
            :items="builders" item-text="name" item-value="builderId"
            outlined
            dense
            ><option v-for="name in builders.name"  v-bind:key="name">{{ name }}</option>
            </v-select>
          </v-col>
        </v-row>

          <small class="font-weight-thin">* If Builder unknown leave blank. If builder known and not listed, create builder first</small>
          <v-spacer></v-spacer>

            <v-row class="pt-0 pb-0 mb-n2 mt-0" align="center">
              <v-col md="3">
                <v-text-field
                label ="L.O.A (Ft)"
                name = "loa"
                outlined
                v-model = design.loa
                dense
                >
                </v-text-field>
              </v-col>
            <v-col md="3">
              <v-text-field
              label ="D.W.L (Ft)"
              name = "dwl"
              outlined
              v-model = design.dwl
              dense
              >
              </v-text-field>
            </v-col>
            <v-col md="3">
          <v-text-field
          label ="Beam (Ft)"
          name = "beam"
          outlined
          v-model = design.beam
          dense
          >
          </v-text-field>
        </v-col>
          <v-col md="3">
            <v-text-field
            label ="Draft (Ft)"
            name = "draft"
            outlined
            v-model = design.draft
            dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

      <v-container>
        <v-col md="12" class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            v-if="notSaved"
            color="blue darken-1"
            text
            outlined
            :disabled="!isFormValid"
            @click="save"
          >
            INPUT DESIGN
          </v-btn>
        </v-col>
          <v-alert
            icon="mdi-shield-lock-outline"
            prominent
            text
            type="success"
            v-if="postSuccess">
            <v-row align="center">
              <v-col class="grow">
                Design entry succesful
              </v-col>
              <v-col class="shrink">
                <v-btn
                  prominent
                  color="green -1"
                  @click="navigateTo">
                  <v-icon>mdi-check-circle-outline</v-icon>
                  ACCEPT
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
            <v-alert
              border="right"
              colored-border
              type="error"
              v-if="error">{{error}}>
            </v-alert>
          </v-container>
  </v-container>
</template>

<script>
import DesignsService from '@/services/DesignsService'
import BuilderService from '@/services/BuilderService'

export default {
  data () {
    return {
      notSaved: true,
      postSuccess: false,
      zIndex: 0,
      error: '',
      valid: false,
      design: {
      DesignOverview: {
          designOverview: null,
      }
    },
      builders: [{
        name: '',
        builderId: ''
      }],
      isFormValid: false,
      DesignRules: [
        value => !!value || 'Design No. is required',
        (value) => !!value || 'Please enter Design No.',
        (value) => (value && value.length <= 4) || 'maximum 4 numbers',
      ],
      YearRules: [
        (value) => (value && value.length === 4) || 'Must be whole year',
      ]   
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  },
  mounted:
  async function () {
    try {
      this.builders = (await BuilderService.dropDown()).data
    } catch (error) {
      this.error = error.message
      console.log(error)
    }
  },
  methods: {
  async save () {
    try {
      this.design.editedBy = this.$store.state.auth.user.id
      this.design.createdBy = this.$store.state.auth.user.id
      await DesignsService.post(this.design)
      this.notSaved=false
      this.postSuccess=true
    } catch (err) {
        this.error = err.message
        console.log(err)
    }
  },
    navigateTo () {
      this.$router.push({
        name: 'Designs'
      })
    }
 }
}
</script>

<style scoped>

</style>
