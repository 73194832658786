<template>
  <v-container fluid class="grey lighten-5">
    <v-row align="center" class="d-flex justify-space-between mb-6">
      <v-col md="8">
        <h1 class="font-weight-light">Drawing № <span class="font-weight-bold">{{drawing.dwgNumber}}</span><span class="font-weight-thin">  {{drawing.drawingtype.type}}</span></h1>
          <br>
      </v-col>

    <v-container v-if="loading" class="d-flex justify-space-around mb-6">
      <v-overlay 
        :value="overlay"
        :z-index="zIndex">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

  <v-col align="end" md="4" class="pl-0 pt-8">

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          text
          @click="navigateTo({
              name: 'viewDesign',
              params: {
                DesId: drawing.design_id
              }
            })"
          ><h3 class="font-weight-thin">Design № {{drawing.design_id}}</h3>
        </v-btn>
      </template>
      <span v-if="drawing.design">Explore {{drawing.design.nameClass}} for {{drawing.design.client}}</span>
    </v-tooltip>

      </v-col>
       </v-row>
       <v-row align="start" class="mt-0 mb-0 pt-0 pb-0">
         <v-col md="12" class="d-flex pa-2">
          <v-img
          class="mt-0 mb-0 pt-0 pb-0"
          contain
          :src="imageLink"
          />
        </v-col>
       </v-row>

          <v-row>
      <v-col>
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th class="text-left">
                  Drawing Number
                </th>
                <th class="text-left">
                  Year
                </th>
                <th class="text-left">
                  Alteration
                </th>
                <th class="text-left">
                  Initials
                </th>
                <th class="text-left">
                  Notes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ drawing.dwgNumber }}</td>
                <td>{{ drawing.date }}</td>
                <td>{{ drawing.alt }}</td>
                  <td>
                    <div class="pr-3" v-for="item in drawing.architects" :key="item.designer_id" style="display:inline">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="navigateTo({
                                name: 'DesignerDrawing',
                                params: {
                                  archId: item.designer_id
                                }
                              })">
                          {{ item.drawingInitials }}
                          </v-chip>
                        </template>
                        <span>All drawings by {{item.name}}</span>
                      </v-tooltip>
                    </div>
                  </td>
                <td>{{ drawing.note }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

      <!--<v-col>
        <div v-for="item in drawing.architects" :key="item.designer_Id">
          <div>{{item.designer_Id}}-{{item.drawingInitials}}</div>
        </div>
      </v-col>-->

        <v-alert
          border="right"
          colored-border
          type="error"
          v-if="error">{{ error }}>
        </v-alert>

  </v-container>
</template>

<script>
import DrawingService from '@/services/DrawingService'
export default {
  data: () => ({
      imageLink: '',
      loading: true,
      overlay: true,
      zIndex: 0,
      error: '',
      drawing: {
        design: {},
        architects: [{
            drawingInitials: ''
          }],
        drawingtype: {
          type: '',
        }
      },
  }),
  async created () {
    try {
    const DwgId = this.$route.params.DwgId
    this.drawing = (await DrawingService.show(DwgId)).data
    this.imageLink = this.drawing.fileUrl
      this.loading = false
      this.overlay = false
    } catch(err) {
      this.error = err.message
    }
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  },
  computed: {
    Initials() {
      var arr = this.drawing.architects
      var object = arr.reduce(
        (obj, item) => Object.assign(obj, { [item.drawingInitials]: item.designer_id }), {})
        return object
        
    }
  }
}

</script>

<style lang="css" scoped>
tr:hover {
        background-color: transparent !important;
     }
</style>
