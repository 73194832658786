import Api from '@/services/Api'
import authHeader from '@/services/Auth-header'
import store from '@/store/index'
import router from '@/router/index'


export default {
  index () {
    return Api().get('designs', { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
  designDropDown () {
    return Api().get('designs/designlist', { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
  post (design) {
    return Api().post('curator/designs/add', design, { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
  show (DesId) {
    return Api().get(`designs/${DesId}`, { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
  put (design) {
    return Api().put(`designs/${design.designNumber}`, design, { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
  getCuratorDesigns() {
    return Api().get('curator/designs', { headers: authHeader() })
    .catch(function(error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.status)
        store.dispatch('auth/logout')
        router.push('/login')
      } else {
        // Handle error however you want
      }
    })
  },
}
