<template>
<v-container fluid>
  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="3">
        <h1 class="font-weight-light">Design List as Curator</h1>
    </v-col>
    <v-col md="3">
      
    </v-col>

    <v-col md="3" justify="end">
      <v-text-field
        outlined
        dense
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-col>
    </v-row>

    <v-container v-if="loading" class="d-flex justify-space-around mb-6">
      <v-overlay 
        :value="overlay"
        :z-index="zIndex">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

    <br>
    <v-data-table
      dense
      :headers="headers"
      :items="designs"
      item-key="design_id"
      :items-per-page="15"
      :search="search"
      @click:row="goToDesign"
      class="row-pointer"
    >
    
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Edit Designs</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
          <template>
            <DesignCreate/>
          </template>
          </v-toolbar>
          </template>
  
        
    
    </v-data-table>

    <div class="table-footer-prepend d-flex pl-2 align-center justify-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            color="grey"
            class="mr-3"
            v-on="on"
            @click="navigateTo"
          >
            <v-icon>mdi-weight-kilogram</v-icon>
          </v-btn>
        </template>
        <span>Switch Units Metric</span>
      </v-tooltip>
    </div>


    <v-alert
      border="right"
      colored-border
      type="error"
      v-if="error">{{error}}>
    </v-alert>

</v-container>
</template>

<script>
import DesignsService from '@/services/DesignsService'
import DesignCreate from '@/components/Curator/DesignCreate'

export default {
  name: 'App',
   components: {
     DesignCreate
   },

  data: () => ({
    loading: true,
    overlay: true,
    zIndex: 0,
    search: '',
    error: '',
    designs: [],
    title: '',
    headers: [
      { text: 'Design №', align: 'left', sortable: true, value: 'design_id' },
      { text: 'Year', value: 'year', sortable: true },
      { text: 'Client', value: 'client', sortable: true },
      { text: 'Name / Class', align: 'center', value: 'nameClass', sortable: true },
      { text: 'Job Description', value: 'jobDescription', sortable: true },
      { text: 'L.O.A (ft)', value: 'loa', sortable: true },
      { text: 'D.W.L (ft)', value: 'dwl', sortable: true },
      { text: 'Beam (ft)', value: 'beam', sortable: true },
      { text: 'Draft (ft)', value: 'draft', sortable: true },
      { text: 'Design Type', value: 'designType', sortable: true }
    ]
  }),
  async created () {
    try {
      this.designs = (await DesignsService.getCuratorDesigns()).data
      this.loading = false
      this.overlay = false
    } catch(err) {
      this.error = err.message
    }
    
  },
  methods: {
    goToDesign (item) {
      this.$router.push(`/designs/${item.design_id}`)
    },
    navigateTo () {
      this.$router.push({
        name: 'DesignsMetric'
      })
    }
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
