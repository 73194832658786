<template>
<v-container fluid>

    <v-container v-if="loading">
      <v-overlay 
        :value="overlay"
        :z-index="zIndex">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

  <v-row align="center" class="d-flex justify-space-between mb-6">
    <v-col md="8">
        <h1 class="font-weight-light">Drawing №<span></span>&#32;<span class="font-weight-bold">{{latest.dwgNumber}}</span></h1>

    </v-col>
    <v-col md="4">
        <h4 class="font-weight-thin">
        Adding drawing as: <span class="font-weight-medium">{{currentUser.username}}</span>
        </h4>
    </v-col>
    </v-row>

      <v-form v-model="isFormValid">

<!--TEMP INSERT DRAWING FORM-->

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="drawing.designNumber"
            :counter="10"
            label="DesignNumber"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="drawing.design_id"
            :counter="10"
            label="Design Id - 9998-C27"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="drawing.dwgSuffix"
            :counter="10"
            label="Dwg Suffix - Z28"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="drawing.dwgType_id"
            :counter="10"
            label="Dwg Type Id - 1"
            required
            outlined
          ></v-text-field>
        </v-col>

<!--START oF FRUITS-->

  <v-container fluid>
    <v-select
      v-model="drawing.selectedInitials"
      :items="designers"
          item-text="drawingInitials"
          item-value="designer_id"
      label="Drawing Signatures"
      multiple
      chips
    >
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
        >
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </v-container>
<!--<END OF MULTIPLE V-SELECT---->
      </v-form>

      <v-container>
        <v-col md="12" class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            color="blue lighten-1"
            text
            outlined
            :disabled="!isFormValid"
            @click="create"
          >
            INPUT DRAWING
          </v-btn>
        </v-col>
          </v-container>
    </v-container>   
</template>

<script>
import ArchitectService from '@/services/ArchitectService'
import DrawingService from '@/services/DrawingService'


export default {
data: () => ({
    loading: true,
    overlay: true,
    zIndex: 0,
    designers: [{ id: '', drawingInitials: '', }],
    latest: '',
    isFormValid: false,
    architectDrawing: '',
    drawing: {
      selectedInitials: []
    }
}),
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
//      likesSomeFruit () {
//          console.log(this.drawing.selectedInitials)
//        return this.drawing.selectedInitials.length > 0 && !this.likesAllFruit
//      },
  },
  async created () {
    try {
      this.latest = (await DrawingService.findLatest()).data
      this.loading = false
      this.overlay = false
    } catch(err) {
      this.error = err.message
    }
  },
  async mounted () {
    try {
      this.designers = (await ArchitectService.initialsDropDown()).data
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async create () {
      this.notSaved = false
      this.loading = true
      this.overlay = true
      try {
        await DrawingService.postTest(this.drawing)
      this.loading = false
      this.overlay = false
      } catch (err) {
        console.log(err)
      }
    },
    toggle () {
    this.$nextTick(() => {
        //if (this.likesAllFruit) {
        return this.drawing.selectedInitials = []
        //} else {
        //this.drawing.selectedInitials = this.designers.slice()
        //}
    })
    },
  }
}

</script>

<style>
.v-input--dense.v-text-field--outlined .v-chip {
  height: 24px;
  margin-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
}
</style>