<template>
<v-container fluid class="grey lighten-5">
  <v-card
        class="mx-auto my-12"
        max-width="374"
      >

        <v-img
          :src="require('@/assets/1673 - Lines Plan.jpg')"
          contain
        >
        <v-alert
          border="right"
          icon="mdi-alert-outline"
          colored="red"
          type="error"
          v-if="error">
          {{error}}
        </v-alert>   
        </v-img>
      
      <v-form v-model="isFormValid" @submit.prevent="handleLogin">
        <v-container>
          <v-row class="my-0 py-0">
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                name="field"
                v-model="user.username"
                :rules="userRules"
                label="User Name"
                required
                placeholder="User Name"
                solo
                prepend-icon="mdi-account-circle"
              ></v-text-field>
            
            <v-text-field
                name="field"
                type="password"
                v-model="user.password"
                :rules="passwordRules"
                 placeholder="Password"
                solo
                prepend-icon=mdi-shield-key-outline
              ></v-text-field>
            </v-col>
          </v-row>
    <v-row  class="mt-n8 pt-n5" justify="center">
      <v-col class="d-flex justify-end">
          <v-btn
            class="grey--text text--darken-1 font-weight-bold"
            color="grey" 
            :disabled="!isFormValid"
            outlined
            @click="handleLogin"
            >
            <span class="grey--text text--darken-1 font-weight-bold">Login</span>
          </v-btn>
      </v-col>
    </v-row>     
        </v-container>
      </v-form>
  </v-card>
  </v-container>
</template>

<script>
import User from '@/models/user'

export default {
  data () {
    return {
      user: new User('',''),
      isFormValid: false,
      error: '',
      userRules: [
        v => !!v || 'Name is required',

      ],
      passwordRules: [
        value => !!value || 'Password is required',
        (value) => !!value || 'Please enter password.',
        (value) => (value && value.length >= 8) || 'minimum 8 characters',
      ],      
    }
},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin() {
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/');
            },
            error => {
              this.error = error.response.data.error
            }
          );
        }
      }
    }
  }
</script>

<style scoped>


</style>