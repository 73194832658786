<template>
  <v-container fluid class="grey lighten-5">
    <v-row class="mb-1" align="start">
      <v-col md="8">
        <h1 class="font-weight-light">Design №<span></span>&#32;<span class="font-weight-bold">{{design.design_id}}</span><span class="font-weight-thin"> - {{design.nameClass}}</span></h1>
        <h3 class="font-weight-thin">{{design.client}}</h3>
        <br>
        <div v-if="design.architect">
        <v-btn
        class="ma-n4"
        color="grey"
        plain>
        <h3 class="font-weight-bold">{{design.architect.name}}</h3>
        </v-btn>
        </div>
        <br>
      </v-col>
      
      <v-col align="end" md="4" class="pl-0 pt-8">
        <div v-if="design.builder">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                text
                @click="navigateTo({
                      name: 'ViewBuilder',
                      params: {
                        BuildId: design.builder.builder_id
                        }
                      })">
                    <v-icon small class="pr-3">mdi-hammer-wrench</v-icon>
                  <h3 class="font-weight-thin">{{design.builder.name}}</h3>
                </v-btn>
              </template>
            <span>Explore builder {{design.builder.name}}</span>
          </v-tooltip>
        </div>
      </v-col>
      
    </v-row>

    <v-container v-if="loading" class="d-flex justify-space-around mb-6">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-container>

<v-container fluid class="ma-0 pa-0">
        <v-row class="mb-10 pa-0">
            <v-col class="ma-0 pa-2" v-if="design.designoverview">
              <p class="font-weight-light">{{design.designoverview.overview}}</p>
                  <small>{{design.designoverview.author}}</small>&#32;<small>{{design.designoverview.authDate}}</small>
              </v-col>
              
                <v-col v-if="design.photos.length" align="end" :cols="3" class="ma-0 pa-2">
                  <div v-for="photos in design.photos.slice(0, 1)" :key="photos.fileUrl">
                    <v-img 
                    contain
                    :width="500"
                    :src="photos.fileUrl"
                    ></v-img>
                  </div>
                </v-col>
            </v-row>
</v-container>

    <v-row>
      <v-col>
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th class="text-left">
                  Job Description
                </th>
                <th class="text-left">
                  Year
                </th>
                <th class="text-left">
                  L.O.A (Ft)
                </th>
                <th class="text-left">
                  D.W.L (Ft)
                </th>
                <th class="text-left">
                  Beam (Ft)
                </th>
                <th class="text-left">
                  Draft (Ft)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ design.jobDescription }}</td>
                <td>{{ design.year }}</td>
                <td>{{ design.loa }}</td>
                <td>{{ design.dwl }}</td>
                <td>{{ design.beam }}</td>
                <td>{{ design.draft }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <br>
<v-container fluid v-for="drawings in design.drawings" :key="drawings.dwgNumber" class="mb-n4">
        <v-card outlined tile>
         <v-list-item three-line>
          <v-img
          class="pointer-link"
          contain
          aspect-ratio="1.7"
          :src="drawings.fileUrl"
          @click="navigateTo({
              name: 'DrawingView',
              params: {
                DwgId: drawings.dwgNumber
              }
            })"></v-img>
          <v-list-item-content class="mb-6 pt-4 pb-4 pl-8">
            <v-list-item-title class="font-weight-thin headline mb-1">
              Drawing №: <span class="font-weight-bold">{{drawings.dwgNumber}}</span></v-list-item-title>
            <v-list-item-subtitle>Design №:  <span class="font-weight-bold">{{drawings.design_id}}</span></v-list-item-subtitle>
              <div class="overline mb-4">
                {{drawings.drawingtype.type}}
              </div>
            <v-list-item-subtitle v-if="drawings.architects.length">
              Drawing initials:
                <div class="pl-3" v-for="item in drawings.architects" :key="item.designer_Id" style="display:inline">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="navigateTo({
                                name: 'DesignerDrawing',
                                params: {
                                  archId: item.designer_id
                                }
                              })">
                          {{ item.drawingInitials }}
                          </v-chip>
                        </template>
                        <span>All drawings by {{item.name}}</span>
                      </v-tooltip>
                    </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-divider></v-divider>
              <v-row>
                <v-col>
                  <div class="overline mb-4">
                {{drawings.fileSize}}:Kb
                  </div>
                </v-col>
              <v-col>
                <div class="overline mb-4">
                  {{drawings.fileType}}
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
          </v-list-item-content>
          </v-list-item>
          </v-card>
</v-container>

        <v-alert
          type="error"
          v-if="error">{{ error}}
        </v-alert>
  </v-container>
</template>

<script>
 import DesignsService from '@/services/DesignsService'

export default {
  data: () => ({
    loading: true,
    overlay: true,
    zIndex: 0,
    error: '',
    design: {
      architect: {},
      builder: {},
      photos: [{
        fileUrl: '',
      }],
      drawings: [{
        drawingtype: {},
        architects: []
      }],
      designoverview: {
          overview: null,
      }
    },
  }),
  async created () {
    try {
      const DesId = this.$route.params.DesId
      this.design = (await DesignsService.show(DesId)).data 
      this.loading = false
      this.overlay = false
    } catch(err) {
      this.error = err.message
    }
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.font-weight-light {
  white-space: break-spaces;
}
i.v-icon.v-icon {
  color: grey;
}
tr:hover {
        background-color: transparent !important;
     }
.pointer-link {
    cursor: pointer;
}
</style>
