<template>
  <v-container fluid class="grey lighten-5">
    <v-row class="mb-6" align="center">
      <v-col md="4">
        <h1 class="font-weight-light">All Photographs</h1>
         <small>Archived Photos</small>
      </v-col>
       </v-row>

  <v-row>
    <v-col
      v-for="photo in photos" :key="photo.id" 
      class="d-flex child-flex"
      cols="3"
    >

  <v-card
    class="mx-auto my-2"
    max-width="300"
  >
    <v-img
      class="pointer-link"
      height="250"
      contain
          :src="photo.fileUrl"
          @click="navigateTo({
              name: 'viewDesign',
              params: {
                DesId: photo.design.design_id
              }
            })"></v-img>
<div v-if="photo.design">
    <v-card-title>{{photo.design.nameClass}}</v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >

        <div class="grey--text ms-4">
          {{photo.design.client}}
        </div>
      </v-row>

      <div class="my-4 text-subtitle-1">
          <small>{{photo.description}}</small>
          <br>
          <small>{{photo.datetaken}}</small>
        
      </div>

    </v-card-text>

    <v-card-title class="font-weight-thin headline mb-1">Design №:<span class="font-weight-bold">{{photo.design.design_id}}</span></v-card-title>

   <v-divider class="mx-4"></v-divider>

    <v-card-text>
      <div class="overline mb-4">
          <small>{{photo.fileSize}}:Kb</small><span>&nbsp;</span><small>{{photo.fileType}}</small>
        
      </div>
    </v-card-text>

    <v-card-actions>

    </v-card-actions>
    </div>
  </v-card>
    </v-col>
  </v-row>


  </v-container>
</template>

<script>
import PhotoService from '@/services/PhotoService'

export default {
  data () {
    return {
      photos: {
          design: {
          }
      }
    }
  },
  async created () {
    this.photos = (await PhotoService.index()).data
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.pointer-link {
    cursor: pointer;
}
</style>