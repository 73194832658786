<template>
<v-container fluid class="grey lighten-5">

    <v-row class="mb-1" align="start">
      <v-col md="4">
        <h1 class="font-weight-light">{{designer.name}}</h1>
        <h3 class="font-weight-thin">{{designer.drawingInitials}}</h3>
      </v-col>
      <v-col md="6">
        <h4>Page under development</h4>
      </v-col>
    </v-row>

 <v-data-table
    dense
    :headers="headers"
    :items="designer.drawings"
    item-key="dwgNumber"
    :items-per-page="15"
    sort-by="designNumber"
    :sort-asc="true"
    group-by="design_id"
    @click:row="goToDrawing"
    class="row-pointer">

        <template v-slot:[`item.architects`]="{ item }">
          <div class="pr-3" v-for="architects in item.architects" :key="architects.designer_Id" style="display:inline">
            <td>
              <v-chip x-small>
              {{ architects.drawingInitials }}
              </v-chip>
              </td>
          </div>
        </template>

  </v-data-table>

  </v-container>
</template>

<script>
import ArchitectService from '@/services/ArchitectService'

export default {
  data: () => ({
    designer: {
      drawings: []
    },
    headers: [
      { text: 'Design №', sortable: true, value: 'design_id' },
      { text: 'Name / Class', sortable: true, value: 'design.nameClass' },
      { text: 'Drawing №', sortable: true, value: 'dwgNumber' },
      { text: 'Description', value: 'drawingtype.type', sortable: true },
      { text: 'Date', value: 'date', sortable: true },
      { text: 'Alteration', align: 'center', value: 'alt', sortable: true },
      { text: 'Designer Initials', align: 'center', value: 'architects', sortable: true },
      { text: 'Size (Kb)', value: 'fileSize', sortable: true }
    ]
  }),
  async created () {
    const archId = this.$route.params.archId
    this.designer = (await ArchitectService.architectsDrawings(archId)).data
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    },
    goToDrawing (item) {
      this.$router.push(`/drawings/${item.dwgNumber}`)
    }
  }
}
</script>

<style scoped>
.font-weight-light {
  white-space: break-spaces;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
