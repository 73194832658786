import axios from 'axios'

export default () => {
    return axios.create({
        baseURL: `/api/`,
    })
}

//const Api =  axios.create({
//        baseURL: `/api/`,
//    })
//Api.interceptors.request.use(
//    response => response,
//    error => errorHandler(error)
//  )

//module.exports = Api

//export default Api() {

//    const Api =  axios.create({
//        baseURL: `/api/`,
//    })
//Api.interceptors.request.use(
//    response => response,
//    error => errorHandler(error)
//  )

//}